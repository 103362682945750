<template>
  <div v-cloak>
    <transition name="fade" appear appear-active-class="fade-enter-active">

        <ProjectsComponent/>

    </transition>
  </div>
</template>

<script>

import ProjectsComponent from '@/components/projects/ProjectsComponent.vue'

export default {
  name: 'Projects',
  components: {
    ProjectsComponent
  }
}
</script>

<style lang="scss">
@import "../styles/main.scss";
</style>

<style scoped lang="scss">
</style>
