<template>
  <div>
    <v-col cols="2">
      <v-select
        :items="years"
        v-model="year"
        outlined
        dense
      ></v-select>
    </v-col>
    <!-- <v-tabs>
      <v-tab
        v-for="year in years"
        :key="year + 'tabinregisterTitle'"
        @click="selectedYear = year"
        >{{ year }}</v-tab
      >
      <v-tab-item
        v-for="year in years"
        :key="year + 'tabinregisterContents'"
        transition="fade-transition"
        reverse-transition="fade-transition"
      >
        <ProjectsRegisterTab
          :year="year"
          :selectedYear="selectedYear"
          :select-mode="selectMode"
          @select="$emit('select', $event)"
          :projectsSums="projectsSums"
        ></ProjectsRegisterTab>
      </v-tab-item>
    </v-tabs> -->
    <ProjectsRegisterTab
      :year="year"
      :config="config"
      :select-mode="selectMode"
      @select="$emit('select', $event)"
      :projectsSums="projectsSums"
    >
    <template v-slot="{ offerExperts, entry, passport, select, selectMode }">
      <ProjectEntry
        :offer-experts="offerExperts"
        :entry="entry"
        :passport="passport"
        @select="select($event)"
        :select-mode="selectMode"
      ></ProjectEntry>
    </template>
  </ProjectsRegisterTab>
  </div>
</template>

<script>
import ProjectsRegisterTab from '@/components/register/ProjectsRegisterTab';
import ProjectEntry from '@/components/register/ProjectEntry'
import { projectsRegisterConfig } from '@/components/register/RegisterConfigs';


export default {
  name: 'ProjectsRegister',
  components: { ProjectsRegisterTab, ProjectEntry },
  props: ['selectMode', 'projectsSums'],
  data() {
    return {
      config: projectsRegisterConfig,
      years: [2021, 2022, 2023, 2024, 2025, 2026],
      year: new Date().getFullYear(),
    };
  }
};
</script>

<style scoped>
.v-tabs:not(.v-tabs--vertical) .v-tab {
  letter-spacing: normal;
  padding: 0 10px;
}
</style>
